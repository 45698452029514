<template>
  <flex column class="px-20 py-10 controls">
    <flex v-if="isSearchOpen" align-items="center">
      <uiInput
        small
        placeholder="Поиск"
        v-model="query"
        @keypress.enter="search"
      >
        <template #after>
          <icon class="font-color-gray" name="search" @click="search" />
        </template>
      </uiInput>
      <icon name="close" class="font-color-gray ml-10" @click="onCloseSearch" />
    </flex>
    <flex
      v-else
      align-items="center"
      :justify-content="entity !== 'ro' ? 'space-between' : 'end'"
    >
      <flex class="bg-primary_l-10 border-radius-5">
        <radio-group
          v-if="entity !== 'ro'"
          button
          small
          v-model="landId"
          class="font-uppercase font-bold"
        >
          <radio-option
            v-for="land in lands"
            :key="land.id"
            :disabled="loadingObjects"
            :value="land.id"
          >
            {{ land.shortName }}
          </radio-option>
        </radio-group>
      </flex>
      <flex align-items="center">
        <icon
          class="font-color-gray"
          name="search"
          :size="20"
          @click="isSearchOpen = true"
        />
        <icon
          class="font-color-gray ml-10"
          name="filter"
          :size="20"
          @click="isOpenFilters = 1"
        />
        <uiButton @click="toForm" small primary class="ml-10">
          <icon name="plus" :size="16" />
        </uiButton>
      </flex>
    </flex>
  </flex>

  <flex
    id="objectsROw"
    fill
    class="overflow-auto"
    :class="{ loading: loadingObjects }"
  >
    <uiTable
      v-if="!loadingObjects"
      :columns="gridSchema"
      :items="objects"
      :row-height="70"
      @rowclick="openObject"
    />
  </flex>

  <flex class="px-20 py-15" justify-content="space-between">
    <flex align-items="center">
      <span class="font-bold font-color-gray font-size-13 text-nowrap mr-10">
        Стр:
      </span>
      <uiSelect slim small v-model="page" class="font-bold font-color-gray">
        <option v-for="i in pageCount" :key="i" :value="i">
          {{ i }}
        </option>
      </uiSelect>
    </flex>
    <flex align-items="center">
      <span class="font-bold font-color-gray font-size-13 text-nowrap mr-10">
        На странице:
      </span>
      <uiSelect slim small v-model="limit" class="font-bold font-color-gray">
        <option :value="10">10</option>
        <option :value="25">25</option>
        <option :value="50">50</option>
      </uiSelect>
    </flex>
  </flex>

  <modal full v-if="isOpenFilters">
    <iopr-filters
      :schema="filtersSchema"
      @close="$router.back()"
      @apply="applyFilters"
    />
  </modal>

  <object-card
    v-if="guid"
    :entity="entity"
    :guid="guid"
    @edit="(dataParams.section = $event.section), (isOpenForm = true)"
    @close="$router.back()"
  ></object-card>

  <object-form
    v-if="isOpenForm"
    :dataParams="dataParams"
    :entity="entity"
    :guid="guid"
    @saved="closeForm($event, -3)"
    @formmap="closeForm($event)"
  ></object-form>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
import useLands from '@/composition/useLands';
import useObjects from '@/composition/useObjects';
import useDistricts from '@/composition/useDistricts';
import useDefaultValues from '@/composition/useDefaultValues';
import useStatusList from '@/composition/useStatusList';

export default {
  props: {
    gridSchema: {
      type: Array,
      default: () => []
    },
    filtersSchema: {
      type: Function,
      default: () => []
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const { defaultValues } = useDefaultValues();

    const { lands, currentLandId } = useLands();
    const { defaultStatusId, load: loadStatusList } = useStatusList();
    const {
      objects,
      count: objectsCount,
      load: loadObjects,
      loading: loadingObjects
    } = useObjects();

    const { getAreaById, load: loadDistricts } = useDistricts();

    const entity = computed(() => {
      return route.path.split('/')[1];
    });

    const toForm = () => {
      dataParams.value.coords = [56.229398, 58.024384];
      dataParams.value.area_id = targetAreaId.value;
      isOpenForm.value = 1;
    };

    const isOpenFilters = computed({
      get() {
        return Boolean(route.query.filters);
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            filters: value
          }
        });
      }
    });

    const guid = computed({
      get() {
        return route.query.guid;
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            guid: value
          }
        });
      }
    });

    const dataParams = ref({});
    const isOpenForm = computed({
      get() {
        return Boolean(route.query.form);
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            form: value
          }
        });
      }
    });

    const areaId = computed(
      () => parseInt(route.query.areaId) || defaultValues.value.AREA_ID
    );
    const targetAreaId = computed(() => {
      const area = getAreaById(areaId.value);
      return area?.target_area_id || area?.area_id || null;
    });
    const districtId = computed(() => parseInt(route.query.districtId) || null);
    const year = computed(() => parseInt(route.query.year) || null);
    const statusId = computed(
      () => parseInt(route.query.statusId) || defaultStatusId.value
    );

    const landId = computed({
      get() {
        return parseInt(route.query.landId) || defaultValues.value.LAND_ID;
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            // ...route.query,
            landId: value,
            statusId: undefined,
            page: undefined
          }
        });
      }
    });
    const limit = computed({
      get() {
        return parseInt(route.query.limit) || 10;
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            limit: value,
            page: undefined
          }
        });
      }
    });
    const page = computed({
      get() {
        return parseInt(route.query.page) || 1;
      },
      set(value) {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            page: value
          }
        });
      }
    });

    const isSearchOpen = ref(Boolean(route.query.search));
    const query = ref(route.query.search || '');
    const search = () => {
      if (!query.value) isSearchOpen.value = false;
      // eslint-disable-next-line no-unused-vars
      const { statusId, ...queryParams } = route.query;
      router.push({
        path: route.path,
        query: {
          // ...route.query,
          // landId: route.query.landId,
          ...queryParams,
          search: query.value,
          page: undefined
        }
      });
    };
    const onCloseSearch = () => {
      query.value = undefined;
      search();
    };

    const pageCount = computed(() => {
      return Math.ceil(objectsCount.value / limit.value) || 1;
    });

    const applyFilters = data => {
      router.push({
        path: route.path,
        query: {
          ...route.query,
          ...data,
          filters: undefined,
          page: undefined
        }
      });
    };

    const openObject = item => {
      guid.value = item.guid;
    };

    const closeForm = (ob, to = null) => {
      router.go(to ? to : -1);
      if (ob.guid) {
        setTimeout(() => {
          openObject(ob);
        }, 100);
      }
    };

    const init = () => {
      currentLandId.value = landId.value;
      loadObjects({
        areaId: areaId.value,
        districtId: districtId.value,
        year: year.value,
        statusId: query.value ? null : statusId.value,
        page: page.value,
        limit: limit.value,
        search: query.value
      });
      loadStatusList({
        areaId: areaId.value,
        districtId: districtId.value,
        year: year.value
      });
      loadDistricts();
    };

    // ToDo: Что это и зачем?
    onBeforeRouteUpdate(to => {
      if (to.path.split('/')[2] !== 'objects') return;
      setTimeout(init, 0);
    });
    init();

    return {
      closeForm,
      toForm,
      entity,
      guid,
      openObject,
      isOpenForm,
      lands,
      landId,
      objects,
      loadingObjects,
      isOpenFilters,
      applyFilters,
      pageCount,
      page,
      limit,
      isSearchOpen,
      query,
      search,
      onCloseSearch,
      dataParams
    };
  }
};
</script>

<style lang="scss" scoped>
.controls {
  height: 52px;
}
</style>
