import { reactive, toRefs, ref } from 'vue';
import api from '@/api';
import useApiRouts from './useApiRouts.js';
import useLands from '@/composition/useLands';

const { apiRouts } = useApiRouts();
const { currentLandId } = useLands();

const state = reactive({
  objects: [],
  count: 0,
  loading: false
});

const lastKey = ref(null);

const load = async params => {
  const url = (() => {
    if (currentLandId.value === 1) return apiRouts.value.GET_MUNICIPAL_OBJECTS;
    if (currentLandId.value === 2) return apiRouts.value.GET_PRIVATE_OBJECTS;
  })();
  const currentKey = Object.entries({
    ...params,
    landId: currentLandId.value,
    url
  })
    .flat()
    .sort()
    .join();
  if (currentKey === lastKey.value) return;
  try {
    state.loading = true;
    const {
      data: { data: objects, count }
    } = await api.post(url, {
      area_id: params.areaId,
      district_id: params.districtId,
      year: params.year,
      filter_id: params.statusId,
      search: params.search,
      type: 'object',
      per_page: params.limit,
      page: params.page
    });
    state.objects = objects;
    state.count = count;
    lastKey.value = currentKey;
  } finally {
    state.loading = false;
  }
};

export default () => {
  return {
    ...toRefs(state),
    load
  };
};
